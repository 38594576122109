<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link
      v-if="isFullPage"
      class="brand-logo"
    >
      <platform-logo />
      <h2 class="brand-text text-primary ml-1">
        {{ platformName() }}
      </h2>
      <small
        v-if="platformVersionName()"
        class="pl-50"
      >
        {{ platformVersionName() }}
      </small>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <template v-if="localIsMaintenance">
          <h2 class="mb-1">
            {{ this.$t('errors.server_maintenance.title') }}️
          </h2>
          <p class="mb-2">
            {{ this.$t('errors.server_maintenance.text') }}
          </p>
        </template>
        <template v-else>
          <h2 class="mb-1">
            {{ errorTitle || this.$t('errors.server_not_reachable.title') }}️
          </h2>
          <p class="mb-2">
            {{ errorDescription || this.$t('errors.server_not_reachable.text') }}
          </p>
        </template>

        <b-button
          v-if="retryCallback"
          variant="primary"
          class="mb-2 mx-50 btn-sm-block"
          :disabled="buttonDisabled"
          @click="tryAgain()"
        >
          <template v-if="buttonDisabled">
            <b-spinner
              small
              class="mr-1"
            />
          </template>
          <span v-t="`Try again`" />
        </b-button>

        <b-button
          v-if="!retryCallback"
          variant="primary"
          class="mb-2 mx-50 btn-sm-block"
          :to="{path:'/'}"
        >
          {{ $t('Back to home') }}
        </b-button>

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BButton, BSpinner, BImg,
} from 'bootstrap-vue'
import PlatformLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import useSession from '@/auth/jwt/useJwt'
import { isServerDown } from '@/auth/utils'
import { platformName, platformVersionName } from '../../@core/utils/utils'

export default {
  components: {
    PlatformLogo,
    BLink,
    BButton,
    BSpinner,
    BImg,
  },
  props: {
    errorTitle: {
      type: String,
      default: () => null,
    },
    errorDescription: {
      type: String,
      default: () => null,
    },
    retryCallback: {
      type: Function,
      default: () => null,
    },
    isMaintenance: {
      type: Boolean,
      default: () => false,
    },
    isFullPage: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
      buttonDisabled: true,
      localIsMaintenance: this.isMaintenance,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  mounted() {
    this.buttonDisabled = false

    this.tryAgain()
  },
  methods: {
    platformVersionName,
    platformName,
    tryAgain() {
      this.buttonDisabled = true
      if (this.retryCallback && this.retryCallback.length) {
        this.retryCallback()
        this.buttonDisabled = false
      } else {
        this.$http.get(this.homeEndpoint, { withCredentials: true })
          .catch(error => {
            if (error?.response?.status === 503) {
              this.localIsMaintenance = true
            } else {
              this.localIsMaintenance = false
            }
          })
          .finally(() => {
            if (!isServerDown()) {
              this.redirectToHome()
            } else {
              this.buttonDisabled = false
            }
          })
      }
    },

    redirectToHome() {
      this.$router.push({ name: 'home' })
    },
  },
  setup() {
    const { homeEndpoint } = useSession.jwtConfig

    return {
      homeEndpoint,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
